/** @jsx jsx */
import { Box, jsx, Text, useThemeUI } from 'theme-ui';

const ExperienceCard = ({
  company = '',
  title = '',
  period = '',
  html = '',
}) => {
  const { theme } = useThemeUI();
  return (
    <Box mb="large" sx={{ pageBreakInside: 'avoid' }} className="exp-card">
      <Text
        as="p"
        sx={{
          color: 'gray3',
          fontStyle: 'italic',
          mb: 'medium',
          width: 'max-content',
        }}
        variant="heading"
      >
        {period}
      </Text>
      <Text
        color="white"
        variant="lead"
        as="p"
        sx={{
          marginBottom: 0,
          '@media print': {
            marginBottom: 'small',
          },
        }}
      >
        {title}
        <span sx={{ color: 'gray4', ...sxShowOnPrintStyles, display: 'none' }}>
          &nbsp; | {company}
        </span>
      </Text>
      <Text color="gray4" variant="lead" as="p" sx={{ ...sxHideOnPrint }}>
        {company}
      </Text>
      <div
        className="exp-card-text"
        dangerouslySetInnerHTML={{ __html: html }}
        sx={{
          '& > p': {
            ...theme.text.paragraph,
            color: 'white',
            '@media print': {
              marginBottom: '8px!important',
            },
          },
          '& a': {
            ...theme.text.paragraph,
            color: 'inherit!important',
          },
          // list
          '& ul': {
            ...theme.text.paragraph,
            padding: 0,
            marginLeft: 'small',
            color: 'white',
            '@media print': {
              marginBottom: '8px!important',
            },
          },
          '& li': {
            ...theme.text.paragraph,
            color: 'white',
            paddingBottom: 0,
            marginBottom: '12px',
          },
        }}
      />
    </Box>
  );
};

const sxHideOnPrint = {
  '@media print': {
    display: 'none',
  },
};

const sxShowOnPrintStyles = {
  '@media print': {
    display: 'inline-block',
  },
};

export default ExperienceCard;
